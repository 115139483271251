import React from 'react'
import styled from 'styled-components'
import {SectionTitle} from 'los-design-system/components/Text'
import Container from 'los-design-system/components/Container'
import Brands from 'los-design-system/components/Brands'

export const TitleStyle = styled(SectionTitle)`
  text-align: center;
  text-align: center;
  margin: 80px 0 65px;

  @media (min-width: 992px) {
    text-align: left;
    max-width: 50%;
    margin: 114px 0 120px;
  }
`

const BrandsStyle = styled(Brands)`
  padding: 0;
  margin: 0 auto 64px;
`

const DoohBrands = () => (
  <React.Fragment>
    <Container>
      <TitleStyle as="h3">
        Developed from our experience working with recognizable brands.
      </TitleStyle>
      <BrandsStyle />
    </Container>
  </React.Fragment>
)

export default DoohBrands
