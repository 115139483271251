import React from 'react'
import styled from 'styled-components'

const Svg = styled('svg')`
  .cls-1 {
    fill: none;
    stroke: #000;
    stroke-miterlimit: 10;
    stroke-width: 4px;
  }
`

interface CheckIconProps {
  className?: string
  width: string
  height: string
}

function CheckIcon({height, width, className = ''}: CheckIconProps) {
  return (
    <Svg className={`check ${className}`} viewBox="0 0 36.97 28.12" height={height} width={width}>
      <g>
        <g>
          <polyline className="cls-1" points="1.41 15.03 11.68 25.29 35.56 1.41" />
        </g>
      </g>
    </Svg>
  )
}

export default CheckIcon
