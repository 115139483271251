import React from 'react'
import {graphql} from 'gatsby'
import styled from 'styled-components'
import {ThemeProvider, ResetCss, Footer, Container} from 'los-design-system'
import Navigation from '../components/Navigation'
import Modal from '../components/Modal'
import HomeHeader from '../components/HomeHeader'
import DoohBrands from '../components/DoohBrands'
import HomePosts from '../components/HomePosts'
import HomeQuote from '../components/HomeQuote'
import CallToAction from '../components/CallToAction'
import Seo from '../components/Seo'

const DotsStyle = styled('div')`
  margin-top: 5px;
  height: 80px;
  width: 100%;
  background-size: 9px 18px;
  background-image: url(/media/dots-large.svg);

  @media (min-width: 768px) {
    width: calc(100% + 30px);
    margin-left: -15px;
    height: 70px;
  }
`

interface IndexProps {
  data: {
    site: {
      siteMetadata: {
        title: string
      }
    }
    allMarkdownRemark: {
      nodes: {
        fields: {
          slug: string
        }
        frontmatter: {
          title: string
          description: string
          chapter: number
        }
      }[]
    }
  }
  location: {
    pathname: string
  }
  navigate(to: string): void
}

const Home = ({data, location, navigate}: IndexProps) => {
  const posts = data.allMarkdownRemark.nodes
  return (
    <ThemeProvider theme={{}}>
      <Seo
        article={false}
        author="LifeOnScreen"
        coverImage="https://lifeonscreen-storage.s3.eu-central-1.amazonaws.com/static/assets/LoS-book-cover_mobile-min.png"
        title="Get your Free Copy of the Ultimate Guide | LifeOnScreen"
        description="Start building your digital signage success story on public screens."
      />
      <ResetCss />
      <Navigation pathname={location.pathname} />
      <HomeHeader />
      <DoohBrands />
      <HomePosts posts={posts} navigate={navigate} />
      <Container>
        <DotsStyle />
      </Container>
      <HomeQuote />
      <CallToAction />
      <Footer />
      <Modal navigate={navigate} />
    </ThemeProvider>
  )
}

// @ts-ignore
export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: {fields: frontmatter___chapter, order: ASC}) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          description
          chapter
        }
      }
    }
  }
`
export default Home
