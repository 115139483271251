import React, {useContext} from 'react'
import styled from 'styled-components'
import {Container, Button} from 'los-design-system'
import {Title, SubtitleBold, Text} from 'los-design-system/components/Text'
import Context from '../context/modalContext'
import CheckIcon from './CheckIcon'

const DoohHeaderWrapperStyle = styled('div')`
  background-image: linear-gradient(340deg, #0adbad, #00aeef);
  text-align: center;
  display: grid;

  @media (min-width: 992px) {
    text-align: left;
  }
`

const GridStyle = styled('div')`
  display: grid;

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 70px;
  }
`

const ContentStyle = styled('div')`
  @media (min-width: 992px) {
    padding-top: 110px;
  }
  @media (min-width: 1367px) {
    padding-top: 160px;
  }
`

const TitleStyle = styled(Title)`
  margin: 64px 0 0 0;
  line-height: 1.25;

  @media (min-width: 992px) {
    margin: 0;
  }
`

const SubtitleStyle = styled(SubtitleBold)`
  margin: 32px 0 0 0;
  line-height: 1.29;
`

const ButtonStyle = styled(Button)`
  margin: 64px 0 0 0;
  text-transform: uppercase;

  @media (min-width: 992px) {
    margin: 72px 0 116px;
  }
`

const PictureStyle = styled('picture')`
  @media (min-width: 992px) {
    display: flex;
    align-items: center;
  }
`

const ImgStyle = styled('img')`
  width: calc(100% + 30px);
  margin: 81px 0 30px -15px;

  @media (min-width: 768px) {
    margin: 0 0 30px;
  }
  @media (min-width: 992px) {
    margin: 0 0 47px;
  }
`

const HeaderFooter = styled('div')`
  position: relative;
  padding: 32px 0 64px;

  @media (min-width: 992px) {
    min-height: 119px;
    padding: 0;
    display: flex;
    align-items: center;
  }
  &:before {
    content: '';
    background-color: rgba(0, 0, 0, 0.04);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
`

const FooterGridStyle = styled('div')`
  display: grid;
  grid-template-columns: 60px auto;

  @media (min-width: 992px) {
    display: flex;
    justify-content: center;
  }
`

const CircleStyle = styled('div')`
  width: 34px;
  height: 34px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
`

const CheckIconStyle = styled(CheckIcon)`
  .cls-1 {
    stroke: #0adbad;
  }
`

const FooterText = styled(Text)`
  text-align: left;
  padding-top: 3px;
`

const HomeHeader = () => {
  const {onOpen} = useContext(Context)

  return (
    <DoohHeaderWrapperStyle>
      <Container>
        <GridStyle>
          <ContentStyle>
            <TitleStyle as="h1" color="#ffffff">
              Master Out-Of-Home Advertising.
            </TitleStyle>
            <SubtitleStyle color="#ffffff">
              Learn how to elevate your brand with proven digital out-of-home strategy.
            </SubtitleStyle>
            <ButtonStyle
              text="Send me the book"
              ariaLabel="Send me the book"
              theme="yellow"
              textAlign="center"
              size="large"
              onClick={onOpen}
            />
          </ContentStyle>
          <PictureStyle>
            <source
              media="(max-width: 767px)"
              srcSet="https://lifeonscreen-storage.s3.eu-central-1.amazonaws.com/static/assets/LoS-book-cover_mobile-min.png, https://lifeonscreen-storage.s3.eu-central-1.amazonaws.com/static/assets/LoS-book-cover_mobile@2x.png 2x"
            />
            <source
              media="(min-width: 768px)"
              srcSet="https://lifeonscreen-storage.s3.eu-central-1.amazonaws.com/static/assets/LoS-book-cover_cel.png, https://lifeonscreen-storage.s3.eu-central-1.amazonaws.com/static/assets/LoS-book-cover_cel@2x.png 2x"
            />
            <ImgStyle
              src="https://lifeonscreen-storage.s3.eu-central-1.amazonaws.com/static/assets/LoS-book-cover.png"
              alt="Master Out-Of-Home advertising."
            />
          </PictureStyle>
        </GridStyle>
      </Container>
      <HeaderFooter>
        <Container>
          <FooterGridStyle>
            <CircleStyle>
              <CheckIconStyle height="19px" width="19px" />
            </CircleStyle>
            <FooterText fontWeight={600} color="#ffffff">
              80+ pages of step-by-step strategy for starting and dominating digital out-of-home
              marketing channels!
            </FooterText>
          </FooterGridStyle>
        </Container>
      </HeaderFooter>
    </DoohHeaderWrapperStyle>
  )
}

export default HomeHeader
