import React from 'react'
import styled from 'styled-components'
import Container from 'los-design-system/components/Container'
import {QuoteText} from 'los-design-system/components/Text'

const DoohQuoteWrapperStyle = styled('div')`
  position: relative;
`

const QuoteStyle = styled('div')`
  font-family: Montserrat;
  font-size: 144px;
  font-style: italic;
  text-align: center;
  color: #e6e7e8;
  position: absolute;
  width: 56px;
  height: 172px;
  top: 46px;
  left: calc(50% - 16px);
  transform: translateX(-50%);

  @media (min-width: 768px) {
    top: 51px;
  }
`

const TextStyle = styled(QuoteText)`
  padding-top: 165px;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;

  @media (min-width: 768px) {
    padding-top: 170px;
  }
`

export const TestimonialPersonWrapperStyle = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 16px;

  margin: 32px auto 145px;
  justify-content: center;

  @media (min-width: 768px) {
    margin-bottom: 174px;
  }
`

export const TestimonialPersonImgStyle = styled('img')`
  border-radius: 50%;
  width: 26px;
  height: 26px;
  margin-right: 16px;
`

export const TestimonialPersonTextStyle = styled('span')`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  font-style: italic;
  color: #343132;
`

const DoohQuote = () => (
  <Container>
    <DoohQuoteWrapperStyle>
      <QuoteStyle>“</QuoteStyle>
      <TextStyle>
        One of the best, if not the best book on digital advertising i’ve read in a long time. And
        free, wow. You guys are the best!
      </TextStyle>
      <TestimonialPersonWrapperStyle>
        <TestimonialPersonImgStyle
          src="/media/testimonial.jpg"
          srcSet="/media/testimonial.jpg 1x,/media/testimonial.jpg 2x"
          alt="testimonial"
        />
        <TestimonialPersonTextStyle>
          Balint Kasas, Marketing executive (Hungary)
        </TestimonialPersonTextStyle>
      </TestimonialPersonWrapperStyle>
    </DoohQuoteWrapperStyle>
  </Container>
)

export default DoohQuote
