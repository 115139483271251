import React from 'react'
import styled from 'styled-components'

// Components
import LandingPanel from 'los-design-system/components/LandingPanel'
import GetChapter from '../utils/GetChapter'

const ChaptersWrapperStyle = styled('section')`
  background-color: #f7f7f7;
  padding: 64px 15px;
`

const LandingWrapperStyle = styled('div')`
  margin: 0 auto 32px;
  max-width: 841px;
`

const LandingPanelStyle = styled('div')`
  max-width: 841px;
  cursor: pointer;
  transition: box-shadow 0.15s;
  border: 1px solid #ffffff;
  margin: 0px auto 32px;

  &:hover {
    box-shadow: 0 16px 12px 0 rgba(0, 174, 239, 0.07);
    border: 1px solid #00aeef;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const PanelStyle = styled(LandingPanel)`
  margin-bottom: 0;
`

interface ChaptersProps {
  posts: {
    fields: {
      slug: string
    }
    frontmatter: {
      title: string
      description: string
      chapter: number
    }
  }[]
  navigate(to: string): void
}

const DoohChaptersContainer = ({posts, navigate}: ChaptersProps) => (
  <ChaptersWrapperStyle>
    <LandingWrapperStyle>
      {posts.map(post => (
        <LandingPanelStyle
          key={post.fields.slug}
          onClick={() => {
            navigate(post.fields.slug)
          }}
        >
          <PanelStyle
            defaultExpanded
            showFooter={false}
            badgeText={GetChapter(post.frontmatter.chapter)}
            title={post.frontmatter.title}
            text={post.frontmatter.description}
            footerText="Download our PDF ebook and read the whole chapter."
            footerButtonText="Read more"
            showChevron={false}
            onClick={() => {}}
          />
        </LandingPanelStyle>
      ))}
    </LandingWrapperStyle>
  </ChaptersWrapperStyle>
)

export default DoohChaptersContainer
